import { decrypt } from "./answerEncryptDecrypt";

export const calculateSubjectResults = (exams, subject) => {
  const subjectExam = exams.find(
    (exam) => exam.examName.toLowerCase() === subject.toLowerCase()
  );
  // console.log(subjectExam, "<<<<<<<");
  if (!subjectExam || !subjectExam.isExamCompleted) {
    return { correctAnswers: 0, totalScore: 0 };
  }

  let correctAnswers = 0;
  let totalScore = 0;

  subjectExam.questions.forEach((question) => {
    //   console.log(decrypt(question.answer, "2023"), question.blob_id);
    if (
      subjectExam.answeredByStudent[question.blob_id] ===
      decrypt(question.answer, 2023)
    ) {
      correctAnswers++;
      totalScore += parseInt(question.weight, 10); // Multiply weight for score
    }
  });
  return { correctAnswers, totalScore };
};
