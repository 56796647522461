import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { envData } from "../../envs";
//

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function StudentInfo() {
  const [studentData, setStudentData] = useState("");
  const answers = JSON.parse(localStorage.getItem("results")) || {};
  useEffect(() => {
    getStudentMetaData();
  }, []);
  const getStudentMetaData = async () => {
    const token = localStorage.getItem("token");
    try {
      const req = await axios.get(`${envData.baseApiUrl}/v1/students`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStudentData(req.data);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <Box style={{ padding: "10px" }}>
      {studentData && (
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Full Name</StyledTableCell>
                <StyledTableCell>DOB</StyledTableCell>
                <StyledTableCell>Grade</StyledTableCell>
                <StyledTableCell>School Name</StyledTableCell>
                <StyledTableCell>Country</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  {studentData.firstName + " " + studentData.lastName}
                </StyledTableCell>
                <StyledTableCell>{studentData.dob}</StyledTableCell>
                <StyledTableCell>{studentData.grade}</StyledTableCell>
                <StyledTableCell>{studentData.schoolName}</StyledTableCell>
                <StyledTableCell>{studentData.countryName}</StyledTableCell>
                <StyledTableCell>
                  {" "}
                  <Button
                    disabled
                    style={{ padding: "3px 15px" }}
                    variant="contained"
                  >
                    edit
                  </Button>{" "}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
