import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import $ from "jquery";
import axios from "axios";
import { envData } from "../../envs";

// import Spinner from "./Spinner";
import CircularProgress from "@mui/material/CircularProgress";
import MathTest from "../ExamPage/MathTestt";
import "./proctor.css";

const MathProctorTestComponent = ({ studentMetaData }) => {
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [screenShareEnabled, setScreenShareEnabled] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [screenStream, setScreenStream] = useState(null);
  const [webcamStream, setWebcamStream] = useState(null);
  const [startTest, setStartTest] = useState(false);
  const screenRef = useRef();
  const webRef = useRef();
  const [isDraggingWebcam, setIsDraggingWebcam] = useState(false);
  const [dragStartWebcam, setDragStartWebcam] = useState({ x: 0, y: 0 });
  const [boxPositionWebcam, setBoxPositionWebcam] = useState({ x: 0, y: 0 });
  const draggableBoxWebcamRef = useRef();

  const [isDraggingScreen, setIsDraggingScreen] = useState(false);
  const [dragStartScreen, setDragStartScreen] = useState({ x: 0, y: 0 });
  const [boxPositionScreen, setBoxPositionScreen] = useState({ x: 0, y: 0 });
  const draggableBoxScreenRef = useRef();

  useEffect(() => {
    const checkPermissions = async () => {
      try {
        const cameraStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setCameraEnabled(true);
        cameraStream.oninactive = () => {
          setCameraEnabled(false);
          console.log("Webcam session stopped");
        };
        setWebcamStream(cameraStream);
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setAudioEnabled(true);
        audioStream.oninactive = () => {
          setAudioEnabled(false);
          console.log("Audio session stopped");
        };

        if (webRef.current) {
          webRef.current.srcObject = cameraStream;
        }
        setShowContinueButton(true);
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    };
    checkPermissions();
  }, []);

  // useEffect(() => {
  //   const checkPermissions = async () => {
  //     try {
  //       const stream = await navigator.mediaDevices.getDisplayMedia({
  //         video: {
  //           displaySurface: "monitor",
  //         },
  //       });
  //       let displaySurface = stream
  //         .getVideoTracks()[0]
  //         .getSettings().displaySurface;
  //       if (displaySurface === "monitor") {
  //         setScreenShareEnabled(true);
  //         setScreenStream(stream);
  //         stream.oninactive = () => {
  //           setScreenShareEnabled(false);
  //           console.log("Screen share session stopped");
  //         };
  //         if (screenRef.current) {
  //           screenRef.current.srcObject = stream;
  //         }
  //         setShowContinueButton(true);
  //       }
  //     } catch (error) {
  //       console.error("Error accessing media devices:", error);
  //     }
  //   };
  //   checkPermissions();
  // }, [screenRef]);

  useEffect(() => {
    const draggableBox = draggableBoxWebcamRef.current;
    draggableBox.addEventListener("mousedown", handleMouseDownWebcam);
    draggableBox.addEventListener("mousemove", handleMouseMoveWebcam);
    draggableBox.addEventListener("mouseup", handleMouseUpWebcam);
    return () => {
      draggableBox.removeEventListener("mousedown", handleMouseDownWebcam);
      draggableBox.removeEventListener("mousemove", handleMouseMoveWebcam);
      draggableBox.removeEventListener("mouseup", handleMouseUpWebcam);
    };
  }, [
    webcamStream,
    draggableBoxWebcamRef,
    isDraggingWebcam,
    dragStartWebcam,
    boxPositionWebcam,
  ]);

  // useEffect(() => {
  //   const draggableBox = draggableBoxScreenRef.current;
  //   draggableBox.addEventListener("mousedown", handleMouseDownScreen);
  //   draggableBox.addEventListener("mousemove", handleMouseMoveScreen);
  //   draggableBox.addEventListener("mouseup", handleMouseUpScreen);
  //   return () => {
  //     draggableBox.removeEventListener("mousedown", handleMouseDownScreen);
  //     draggableBox.removeEventListener("mousemove", handleMouseMoveScreen);
  //     draggableBox.removeEventListener("mouseup", handleMouseUpScreen);
  //   };
  // }, [
  //   screenStream,
  //   draggableBoxScreenRef,
  //   isDraggingScreen,
  //   dragStartScreen,
  //   boxPositionScreen,
  // ]);

  const handleMouseDownWebcam = (event) => {
    event.preventDefault();
    setIsDraggingWebcam(true);
    setDragStartWebcam({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMoveWebcam = (event) => {
    if (isDraggingWebcam) {
      const offsetX = event.clientX - dragStartWebcam.x;
      const offsetY = event.clientY - dragStartWebcam.y;
      setBoxPositionWebcam({
        x: boxPositionWebcam.x - offsetX,
        y: boxPositionWebcam.y - offsetY,
      });
      setDragStartWebcam({ x: event.clientX, y: event.clientY });
    }
  };

  const handleMouseUpWebcam = () => {
    setIsDraggingWebcam(false);
  };

  const handleMouseDownScreen = (event) => {
    event.preventDefault();
    setIsDraggingScreen(true);
    setDragStartScreen({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMoveScreen = (event) => {
    if (isDraggingScreen) {
      const offsetX = event.clientX - dragStartScreen.x;
      const offsetY = event.clientY - dragStartScreen.y;
      setBoxPositionScreen({
        x: boxPositionScreen.x + offsetX,
        y: boxPositionScreen.y - offsetY,
      });
      setDragStartScreen({ x: event.clientX, y: event.clientY });
    }
  };

  const handleMouseUpScreen = () => {
    setIsDraggingScreen(false);
  };
  const mathOrCogat = (path) => {
    if (path === "/dash/math") {
      return "Math";
    }
    if (path === "/dash/cogat") {
      return "CogAt";
    }
  };
  const examType = mathOrCogat(location.pathname);
  // const getTimeToStartExam = async () => {
  //   try {
  //     const token = localStorage.getItem("token");

  //     const [request1] = await Promise.all([
  //       axios.get(
  //         `${envData.baseApiUrl}/v1/students/student/exam-time-passed`,
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         },
  //         {
  //           params: {
  //             examId: "EID00003",
  //           },
  //         }
  //       ),
  //     ]);
  //     // console.log(request1, "<<<<<<<<");
  //   } catch (error) {
  //     console.error("Error uploading files:", error);
  //   }
  // };
  const handleExamStarted = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${envData.baseApiUrl}/v1/students/student/start-exam`,
        {
          examId: examType === "Math" ? "EID00001" : "EID00003",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error posting to APIs:", error);
    }
  };
  const handleContinue = () => {
    toggleSidebar();
    setStartTest(true);
    handleExamStarted();
    // getTimeToStartExam();
  };
  const toggleSidebar = () => {
    const isOpen = $(".sidebar").hasClass("open");
    if (!isOpen) {
      $(".sidebar, .contents").toggleClass("open");
    }
  };

  return (
    <div style={{ marginTop: "40px", padding: "20px" }}>
      {startTest && <MathTest studentMetaData={studentMetaData} />}
      {!startTest && (
        <div>
          {cameraEnabled ? (
            <span className="checkmark">&#10004;</span>
          ) : (
            <CircularProgress color="secondary" />
          )}{" "}
          Camera
          <br />
          {audioEnabled ? (
            <span className="checkmark">&#10004;</span>
          ) : (
            <CircularProgress color="secondary" />
          )}{" "}
          Audio
          <br />
          {/* {screenShareEnabled ? (
            <span className="checkmark">&#10004;</span>
          ) : (
            <CircularProgress color="secondary" />
          )}{" "}
          Screen Share
          <br /> */}
          {showContinueButton && (
            <Button
              style={{ marginTop: "10px" }}
              variant="contained"
              onClick={handleContinue}
            >
              Proceed to start
            </Button>
          )}
        </div>
      )}
      {/* 
      {
        <div
          className="draggable-box-screen"
          style={{
            right: boxPositionScreen.x + 20,
            top: boxPositionScreen.y + 20,
          }}
          ref={draggableBoxScreenRef}
        >
          <video autoPlay ref={screenRef}></video>
        </div>
      } */}
      {
        <div
          className="draggable-box-webcamera"
          style={{
            right: boxPositionWebcam.x + 20,
            bottom: boxPositionWebcam.y + 20,
          }}
          ref={draggableBoxWebcamRef}
        >
          <video autoPlay playsInline muted ref={webRef}></video>
        </div>
      }
    </div>
  );
};

export default MathProctorTestComponent;
