import React, { useState, useEffect } from "react";
import axios from "axios";
import { envData } from "../../envs";
import Select from "react-select";
const StudentRow = () => {
  const [student, setStudent] = useState(null); // State to store student data
  const [isEditing, setIsEditing] = useState(false); // Toggle edit mode
  const [formData, setFormData] = useState({}); // Editable form data
  const [countryList, setCountry] = useState([]);

  // Fetch student data from API
  useEffect(() => {
    fetch(`${envData.baseApiUrl}/v1/info/coutries`)
      .then((res) => res.json())
      .then((data) => {
        const countryList = data.map(({ countryName, ...rest }) => ({
          label: countryName,
          ...rest,
        }));
        setCountry(countryList.sort((a, b) => a.label.localeCompare(b.label)));
      });
  }, []);
  useEffect(() => {
    const getStudentMetaData = async () => {
      const token = localStorage.getItem("token");
      try {
        const req = await axios.get(`${envData.baseApiUrl}/v1/students`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStudent(req.data);
        setFormData(req.data);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    getStudentMetaData();
  }, []);
  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${envData.baseApiUrl}/v1/students/student`,
        {
          type: "update",
          teamName: student.teamName,
          country: "C00002",
          countryName: formData.countryName,
          dob: formData.dob,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          gender: formData.gender,
          schoolName: formData.schoolName,
          schoolCity: formData.schoolCity,
          grade: formData.grade,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setIsEditing(false); // Exit edit mode
        window.location.reload(); // Reload the page to show updates
        // setTeamUpdated(true);
        // setOpen(true);
        // setMsg("Team name has been submitted");
      }
    } catch (error) {
      console.error("Error posting to APIs:", error);
    }
  };
  // Handle form input changes
  const handleChange = (e) => {
    if (e.countryCode && e.label) {
      setFormData({
        ...formData,
        countryName: e,
      });
      return;
    }
    const { name, value } = e.target;
    // console.log(name);
    // console.log(value);
    setFormData({ ...formData, [name]: value });
  };
  console.log(formData);
  // Handle save action
  //   const handleSave = async () => {
  //     try {
  //       await axios.put(apiEndpoint, formData); // Update student data via API
  //       setIsEditing(false); // Exit edit mode
  //       window.location.reload(); // Reload the page to show updates
  //     } catch (error) {
  //       console.error("Error saving student data:", error);
  //     }
  //   };

  // Render loader if data isn't available yet
  if (!student) {
    return <p>Loading student information...</p>;
  }

  return (
    <div style={{ padding: "20px", border: "1px solid #ddd", margin: "20px" }}>
      <h2>Student Information</h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          color: "white",
        }}
      >
        {/* Display student information */}
        <div>
          <label>Name:</label>
          {isEditing ? (
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          ) : (
            <span>{student.firstName}</span>
          )}
        </div>

        <div>
          <label>Last Name:</label>
          {isEditing ? (
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          ) : (
            <span>{student.lastName}</span>
          )}
        </div>

        <div>
          <label>Date of Birth:</label>
          {isEditing ? (
            <input
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
            />
          ) : (
            <span>{student.dob}</span>
          )}
        </div>

        <div>
          <label>Country:</label>
          {isEditing ? (
            // <input
            //   type="text"
            //   name="country"
            //   value={formData.countryName}
            //   onChange={handleChange}
            // />

            <Select
              options={countryList}
              value={formData.countryName}
              onChange={handleChange}
            />
          ) : (
            <span>{student.countryName}</span>
          )}
        </div>

        <div>
          <label>School Name:</label>
          {isEditing ? (
            <input
              type="text"
              name="schoolName"
              value={formData.schoolName}
              onChange={handleChange}
            />
          ) : (
            <span>{student.schoolName}</span>
          )}
        </div>

        {/* Action buttons */}
        <div>
          {isEditing ? (
            <button onClick={handleSave} style={{ marginRight: "10px" }}>
              Save
            </button>
          ) : (
            <button onClick={() => setIsEditing(true)}>Edit</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentRow;
