import { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import NavBar from "../../components/NavBar/NavBar";
import HeaderSearch from "../../components/HeaderSearch/HeaderSearch";
import Btn from "../../components/Btn/Btn";
import MobileMenu from "../../components/NavBar/MobileMenu";
import MainSearch from "../../components/NavBar/MainSearch";
import { fakeAuthProvider } from "../../LoginActions";
import { isMobile } from "react-device-detect";
import dashboard from "./dashboard.png";
import { FaUserAlt } from "react-icons/fa";

import "./Heade.css";
const Header = ({ data }) => {
  const navigate = useNavigate();
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const [searchbarShow, setSearchbarShow] = useState(false);
  const onSearchHandler = () => {
    setSearchbarShow((prev) => !prev);
  };
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  useEffect(() => {
    const header = document.querySelector(".header-section");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  const handleClick = () => {
    navigate(process.env.PUBLIC_URL + "/dash");
  };

  const navUserButton = () => {
    return !fakeAuthProvider.isExprired()
      ? fakeAuthProvider.getFullname()
      : "Login/Sign up";
  };
  const urlName = window.location.href;
  const urlContentCheck = () => {
    if (
      urlName.includes("cognitive") ||
      urlName.includes("robotics") ||
      urlName.includes("math-analysis") ||
      isMobile
    ) {
      return "#02771A";
    }
    // return "#02771A";
    return "";
  };
  return (
    <Fragment>
      <div
        style={{
          backgroundColor: urlContentCheck(),
        }}
        className={`header-section header-transparent sticky-header section ${
          scroll > headerTop ? "is-sticky" : ""
        }`}
      >
        <div className="header-inner">
          <div className="container position-relative">
            <div className="row justify-content-between align-items-center">
              <div className="col-xl-2 col-auto order-0">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                />
              </div>
              <div className="col-auto col-xl d-flex align-items-center justify-content-xl-center justify-content-end order-2 order-xl-1">
                <div className="menu-column-area d-none d-xl-block position-static">
                  <NavBar />
                </div>
                <div className="header-search-area ms-xl-7 ms-0">
                  {fakeAuthProvider.isExprired() ? (
                    !isMobile ? (
                      <Link
                        to={process.env.PUBLIC_URL + "/dash"}
                        className="btn btn-primary btn-hover-secondary"
                      >
                        Register / Login
                      </Link>
                    ) : (
                      <button
                        className="button login__submit"
                        onClick={() =>
                          navigate(process.env.PUBLIC_URL + "/dash")
                        }
                      >
                        <span className="button__text">Register now</span>
                        <i className="button__icon fas fa-chevron-right"></i>
                      </button>
                    )
                  ) : !isMobile ? (
                    <HeaderSearch icon={dashboard} />
                  ) : (
                    <button
                      className="button login__submit"
                      onClick={() => navigate(process.env.PUBLIC_URL + "/dash")}
                    >
                      <FaUserAlt />
                      <span
                        style={{ marginLeft: "3px" }}
                        className="button__text"
                      >
                        My profile
                      </span>
                    </button>
                  )}
                </div>

                <div className="header-mobile-menu-toggle d-xl-none ms-sm-2">
                  <button
                    type="button"
                    className="toggle"
                    title="title"
                    onClick={onCanvasHandler}
                  >
                    <i className="icon-top"></i>
                    <i className="icon-middle"></i>
                    <i className="icon-bottom"></i>
                  </button>
                </div>
              </div>

              {/* <div
                className="col-xl-2 col d-none d-sm-flex justify-content-end order-1 order-xl-2"
                onClick={handleClick}
              >
                <Btn name={navUserButton()} />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
      <MainSearch show={searchbarShow} onClose={onSearchHandler} />
    </Fragment>
  );
};

export default Header;
