import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import useScreenSize from "../Intro/useScreenDetect.jsx";

const Logo = ({ image }) => {
  const { width } = useScreenSize();
  const isMobile = width <= 950;
  return (
    <div className="header-logo">
      <Link to={process.env.PUBLIC_URL + "/"}>
        <img
          className="dark-logo"
          src={process.env.PUBLIC_URL + image}
          alt="xCel Logo"
          style={{
            width: isMobile ? "60px" : "100px",
            height: isMobile ? "50px" : "70px",
            cursor: "pointer",
          }}
        />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  image: PropTypes.string,
};

export default Logo;
