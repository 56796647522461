import React from "react";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const Question = ({
  question,
  onAnswerChange,
  userAnswer,
  localStorageTestType,
}) => {
  const handleInputChange = (e) => {
    onAnswerChange(question.blob_id, e.target.value);
  };
  const isPracticeTest = () => {
    if (localStorageTestType() === "practiceCogat") {
      return "70%";
    }
    if (localStorageTestType() === "practiceMath") {
      return "100%";
    }
  };

  const practice =
    localStorageTestType() === "practiceCogat" ||
    localStorageTestType() === "practiceMath";
  return (
    <div>
      <img
        src={question.blob_url}
        alt={`Question ${question.blob_id}`}
        style={{
          // maxWidth: "60%",
          maxWidth: practice ? isPracticeTest() : "100%",
        }}
      />
      {question.qtype !== "open" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {question.qtype.split("").map((option, index) => {
            return (
              <label
                key={index}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "20px",
                  paddingLeft: "50px",
                  marginLeft: "40px",
                  marginTop: "10px",
                }}
              >
                <Radio
                  checked={userAnswer === option}
                  onChange={handleInputChange}
                  value={option}
                  name={`question-${question.blob_id}`}
                  style={{
                    border: userAnswer === option ? "1px solid grey" : "",
                  }}
                />
                {option.toUpperCase()}
              </label>
            );
          })}
        </div>
      ) : (
        <Box>
          <TextField
            style={{
              paddingLeft: "70px",
              width: "250px",
              margin: "10px 0 10px 10px",
            }}
            placeholder="Enter your answer"
            value={userAnswer || ""}
            onChange={handleInputChange}
          />
        </Box>
      )}
    </div>
  );
};

export default Question;
