import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Question from "./Question";
// import questions from "../data/questions.json";
import axios from "axios";
import { envData } from "../../envs";
import Button from "@mui/material/Button";
import ExamFinishModal from "./examFinishModal";
import { isMobile } from "react-device-detect";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
const MathTest = ({ studentMetaData }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [finalResult, setFinalResult] = useState("");
  const [restartQuiz, setShowRestartQuizButton] = useState(false);
  const [msg, setSnackbarMessage] = useState("");
  const [open, setSnackbarOpen] = useState(false);
  const [isExamSubmitted, setExamSubmitted] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const isMathRoute =
    location.pathname === "/dash/practiceMath" ||
    location.pathname === "/dash/practiceCogat";
  const localStorageTestType = () => {
    if (location.pathname === "/dash/math") {
      return "mathTest";
    } else if (location.pathname === "/dash/cogat") {
      return "cogatTest";
    } else if (location.pathname === "/dash/practiceMath") {
      return "practiceMath";
    } else if (location.pathname === "/dash/practiceCogat") {
      return "practiceCogat";
    }
  };
  const initialTime = isMathRoute ? 300 : 4800; // 5 minutes in seconds
  const [timeLeft, setTimeLeft] = useState(
    parseInt(localStorage.getItem(`${localStorageTestType()}Timer`)) ||
      initialTime
  );
  // Initialize answers from localStorage or set to empty object
  const [answers, setAnswers] = useState(() => {
    return JSON.parse(localStorage.getItem(localStorageTestType())) || {};
  });

  const currentQuestion = questions && questions[currentQuestionIndex];
  const getMathQuestions = async () => {
    const token = localStorage.getItem("token");
    try {
      const mathRequest = await axios.get(
        `${envData.baseApiUrl}/v1/students/student/mock/math`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const cogatRequest = await axios.get(
        `${envData.baseApiUrl}/v1/students/student/mock/cogat`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const [mathResponse, cogatResponse] = await Promise.all([
        mathRequest,
        cogatRequest,
      ]);

      // const isEmpty = (obj) => Object.keys(obj).length === 0;
      if (location.pathname === "/dash/practiceMath") {
        setQuestions(mathResponse.data);
      }
      if (location.pathname === "/dash/practiceCogat") {
        setQuestions(cogatResponse.data);
      }

      setLoading(true);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const mathOrCogat = (path) => {
    if (path === "/dash/math") {
      return "Math";
    }
    if (path === "/dash/cogat") {
      return "CogAt";
    }
  };
  const examType = mathOrCogat(location.pathname);
  useEffect(() => {
    // console.log(newData);
    if (isMathRoute) {
      getMathQuestions();
      return;
    }
    const createQuestions = studentMetaData.exams.filter(
      (exam) => exam.examName === examType
    );
    setQuestions(
      createQuestions[0]?.questions?.length ? createQuestions[0]?.questions : []
    );
    setLoading(true);
  }, []);
  // Save answers to localStorage on every answer change
  useEffect(() => {
    localStorage.setItem(localStorageTestType(), JSON.stringify(answers));
  }, [answers]);
  const handleAnswerChange = (blob_id, answer) => {
    const updatedAnswers = { ...answers, [blob_id]: answer.trim() };
    setAnswers(updatedAnswers);
  };
  useEffect(() => {
    // if (!isMathRoute) return;
    let timer;
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem(`${localStorageTestType()}Timer`, newTime); // Save remaining time to localStorage
          return newTime;
        });
      }, 1000);
    } else if (timeLeft === 0) {
      calculateResults(); // Auto-submit when time runs out
      setShowRestartQuizButton(true);
      localStorage.removeItem(`${localStorageTestType()}Timer`); // Clear time from localStorage
    }
    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const calculateResults = () => {
    let score = 0;
    questions.forEach((question) => {
      if (
        answers[question.blob_id] &&
        answers[question.blob_id] === question.answer
      ) {
        score += 1;
      }
    });
    setOpenModal(true);
    setShowRestartQuizButton(true);
    setCorrectAnswers(score);
    setFinalResult(`Your score is: ${score} out of ${questions.length}`);

    // alert(`Quiz finished! Your score is: ${score} out of ${questions.length}`);
  };
  const examSubmitted = () => {
    setExamSubmitted(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleRestartPracticeQuiz = () => {
    setCurrentQuestionIndex(0);
    setShowRestartQuizButton(false);
    setTimeLeft(initialTime);
    localStorage.removeItem(`${localStorageTestType()}Timer`);
  };
  const openModalToSubmitExamAnswers = () => {
    setOpenModal(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  useEffect(() => {
    if (!isExamSubmitted) return;
    setSnackbarOpen(true);
    setSnackbarMessage("Your answers have been submitted, Thank you!");
    setTimeout(() => {
      navigate("/dash"); //  "/dashboard"  actual route
    }, 2000);
  }, [isExamSubmitted]);
  return (
    <>
      {loading ? (
        questions.length ? (
          <div
            style={{
              paddingLeft: "10px",
              marginLeft: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "space-between",
              }}
            >
              <h5>
                Question {currentQuestionIndex + 1} of {questions.length}
              </h5>
              {/* {isMathRoute && <div>Time Left: {formatTime(timeLeft)}</div>} */}
              <div
                style={{
                  border: "1px solid grey",
                  borderRadius: "20px",
                  padding: "4px 10px",
                  color: timeLeft <= 30 ? "red" : "",
                }}
              >
                Time Left: {formatTime(timeLeft)}
              </div>
            </div>
            <Question
              question={currentQuestion}
              userAnswer={answers[currentQuestion.blob_id] || ""} // Use stored answer if available
              onAnswerChange={handleAnswerChange}
              localStorageTestType={localStorageTestType}
            />
            <div
              style={{
                paddingLeft: "50px",
                marginLeft: "30px",
                display: "flex",
                width: "80%",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  style={{ color: "white", marginRight: "4px" }}
                  onClick={handlePrevious}
                  disabled={currentQuestionIndex === 0}
                >
                  Previous
                </Button>
                {currentQuestionIndex < questions.length - 1 ? (
                  <Button
                    style={{
                      backgroundColor: "#52668C",
                      paddingRight: "35px",
                      paddingLeft: "35px",
                      color: "white",
                    }}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                ) : (
                  <>
                    {isMathRoute && (
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "teal",
                          marginRight: "4px",
                        }}
                        onClick={calculateResults}
                      >
                        Finish Quiz
                      </Button>
                    )}
                  </>
                )}
                {restartQuiz && isMathRoute && (
                  <Button
                    variant="outlined"
                    style={{ marginLeft: "5px" }}
                    onClick={handleRestartPracticeQuiz}
                  >
                    Restart Quiz
                  </Button>
                )}
              </div>
              {isMobile && <br />}
              {!isMathRoute && (
                <Button
                  disabled={isExamSubmitted}
                  variant="success"
                  style={{
                    color: "white",
                    backgroundColor: "teal",
                    marginLeft: isMobile ? "0" : "244px",
                    marginTop: isMobile ? "20px" : "0",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                  onClick={openModalToSubmitExamAnswers}
                  // disabled={currentQuestionIndex === 0}
                >
                  Submit final results
                </Button>
              )}
            </div>
            <div style={{ textAlign: "center" }}>
              <Snackbar
                key={"center" + "center"}
                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {msg}
                </Alert>
              </Snackbar>
            </div>
            <ExamFinishModal
              finalResult={finalResult}
              handleCloseModal={handleCloseModal}
              openModal={openModal}
              isMathRoute={isMathRoute}
              studentMetaData={studentMetaData}
              mathOrCogat={examType}
              examSubmitted={examSubmitted}
              timeLeft={timeLeft}
              correctAnswers={correctAnswers}
            />
          </div>
        ) : (
          <>
            {" "}
            <h5>There are no questions to show at this moment!</h5>{" "}
          </>
        )
      ) : (
        "loading"
      )}
    </>
  );
};

export default MathTest;
