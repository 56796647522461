import PropTypes from "prop-types";
import React, { useEffect } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";

const ArtContainer = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  //   const cate = data.categories.map((value, i) => {
  //     return (
  //       <span className="d-inline" key={i}>
  //         {value}
  //         {i !== data.categories.length - 1 && " , "}
  //       </span>
  //     );
  //   });
  return (
    <div className="section section-padding">
      <div className="container">
        <div className="row pt--100 pb--80">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="work-left work-details" data-aos="fade-up">
              <div className="portfolio-main-info">
                <h2 className="title">
                  Exam
                  <br /> Details
                </h2>
                <div className="work-details-list mt-12">
                  <div className="details-list">
                    <img
                      src="images/categoryIcons/date.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Date</label>
                    <span style={{ textAlign: "center" }}>June/2025</span>
                  </div>

                  <div className="details-list">
                    <img
                      src="images/categoryIcons/grade.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Grades</label>
                    <span style={{ paddingLeft: "45px" }}>0-12</span>
                  </div>

                  <div className="details-list">
                    <img
                      src="images/categoryIcons/location.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Location</label>
                    <span style={{ textAlign: "center" }}>UCLA, CA</span>
                  </div>

                  {/* <div className="details-list">
                    <label>Awards</label>
                    <span>asdasdasd</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
            <div className="work-left work-details mt-6">
              <div className="work-main-info">
                <div className="work-content">
                  <h3 className="title" data-aos="fade-up">
                    XCEL ART
                  </h3>

                  <div className="desc mt-8">
                    <h4>Stages of the Competition</h4>
                    <ol>
                      <li>
                        <strong>Preliminary Round (Online)</strong>
                        <ul>
                          <li>
                            <strong>Submission:</strong> Students submit their
                            artworks digitally via the XCEL web platform.
                          </li>
                          <li>
                            <strong> Deadline:</strong>
                            All entries must be uploaded by January 30, 2025.{" "}
                          </li>
                          <li>
                            <strong>Evaluation Criteria:</strong>
                            Submissions are judged based on creativity,
                            technical execution, and their connection to the
                            theme of &quot;Anime, Manga, and Beloved Cartoon
                            Characters.&quot;
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Global Round (In-Person)</strong>
                        <ul>
                          <li>
                            <strong>Location:</strong> University of California,
                            Los Angeles (UCLA), Los Angeles, California.
                          </li>
                          <li>
                            <strong> Date:</strong>
                            June 2025.
                          </li>
                          <li>
                            <strong>Format:</strong>
                            Finalists from the preliminary round will showcase
                            their artwork for a live evaluation by an esteemed
                            panel of judges. The event also includes workshops,
                            exhibitions, and cultural exchange opportunities.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="custom-column-thumbnail mt-lg-14 mt-1"
              data-aos="fade-up"
            >
              {/* <h5>Preliminary Round:</h5> */}
              <h4>XCEL ART SUBMISSION RULES</h4>
              <ol>
                <li>
                  Only <strong>individual submissions </strong> are allowed; no
                  group projects will be accepted. Participants must meet the
                  competition&apos;s age and category requirements.
                </li>
                <li>
                  {" "}
                  Artwork must be completed in a portrait or landscape
                  orientation. The recommended size is between{" "}
                  <strong> 16x20 inches and 18x24 inches.</strong>
                </li>
                <li>
                  Artworks must be submitted in high-quality{" "}
                  <strong>JPEG or PNG</strong> format. File size should not
                  exceed 8 MB. If traditional mediums are used, a
                  high-resolution scan or photograph of the artwork must be
                  submitted. Digital artworks must maintain at least 300 DPI
                  resolution.
                </li>
                <li>
                  Participants are encouraged to submit an optional artist
                  statement (up to 200 words) explaining the inspiration,
                  techniques, and relevance of their work to the competition
                  theme.
                </li>
                <li>
                  All submissions must be entirely original and created by the
                  participant. Works that incorporate copyrighted or
                  pre-existing images, graphics, or materials will be
                  disqualified. Submissions must not have been previously
                  published or entered in any other competition.
                </li>
                <li>
                  File Naming: Use English characters only, and name the file as
                  follows: <strong> LastName_TitleOfArtwork</strong> (e.g.,
                  Smith_SunsetDream.jpg)
                </li>
                <li>
                  By submitting, participants grant XCEL ART non-exclusive
                  rights to display, reproduce, and use the artwork for
                  promotional and educational purposes. Artists retain ownership
                  and copyright of their work.
                </li>
                <li>
                  Failure to adhere to the guidelines or submission of
                  incomplete/low-quality files will result in disqualification.
                  Submissions with offensive or inappropriate content will be
                  rejected.
                </li>
              </ol>
              <h4>JUDGING CRITERIA</h4>
              <ol>
                <li>
                  Creativity and Originality <br />{" "}
                  <strong>Judges Expect:</strong> Artwork that presents a fresh
                  and innovative approach to the theme of Manga, Anime, and
                  Cartoon Characters. This can include new character designs,
                  unique interpretations of existing characters, or original
                  storytelling within the artwork. Judges are looking for
                  originality and an imaginative perspective that stands out
                  from other entries. Participants Should Aim For: Push the
                  boundaries of traditional designs, experiment with new ideas,
                  and present characters or stories in ways that haven’t been
                  overdone in the genre.
                  <br />
                  <strong> Participants Should Aim For:</strong> Push the
                  boundaries of traditional designs, experiment with new ideas,
                  and present characters or stories in ways that haven’t been
                  overdone in the genre.
                </li>
                <li>
                  Composition and Design <br />
                  <strong>Judges Expect:</strong> Well-organized artwork with a
                  clear visual flow. Whether it’s a standalone illustration or
                  part of a larger narrative (like manga panels), the
                  composition should guide the viewer’s eye and create a
                  balanced, harmonious piece. The arrangement of characters,
                  background, and objects should be intentional and contribute
                  to the overall impact of the artwork. <br />{" "}
                  <strong>Participants Should Aim For:</strong> Ensure that all
                  elements (characters, background, props) are positioned in a
                  way that complements each other. Focus on visual balance and
                  structure, whether using symmetry or dynamic asymmetry.
                </li>
                <li>
                  Theme Interpretation <br />
                  <strong>Judges Expect:</strong>Clear and effective
                  communication of the theme, which in this case revolves around
                  Manga, Anime, and Cartoon Characters. The artwork should
                  resonate with the audience and clearly reflect the core
                  concepts of these art forms—whether through character design,
                  emotion, or narrative. <br />{" "}
                  <strong>Participants Should Aim For:</strong> Ensure the theme
                  is prominent in the work. If it&apos;s a character design,
                  make sure the design fits the Manga/Anime style. If it&apos;s
                  a scene, make sure the setting, mood, and elements all
                  contribute to the theme. the theme.
                </li>
                <li>
                  Theme Interpretation <br />
                  <strong>Judges Expect:</strong>Artworks that evoke emotion
                  from the viewer, whether joy, nostalgia, excitement, or even
                  tension. Manga, Anime, and Cartoons are deeply emotional
                  mediums, and judges are looking for pieces that can create
                  this emotional connection. <br />{" "}
                  <strong>Participants Should Aim For:</strong> Consider the
                  mood or tone you want to convey. Think about how your
                  characters&apos; expressions, poses, and settings can elicit
                  emotions from the viewer.
                </li>
                <li>
                  Technical Skill and Craftsmanship <br />
                  <strong>Judges Expect:</strong>A high level of proficiency in
                  the chosen medium, whether digital or traditional. This
                  includes clean lines, accurate proportions, appropriate
                  shading, color usage, and overall polish. Judges will evaluate
                  the technical execution to ensure that participants have
                  mastery over the materials they’ve chosen to use. <br />{" "}
                  <strong>Participants Should Aim For:</strong> Take time to
                  perfect the technical aspects—whether that’s achieving smooth
                  line work in digital illustrations or mastering shading and
                  texture in traditional pieces. Attention to detail is
                  important.
                </li>
                <li>
                  Innovation and Vision <br />
                  <strong>Judges Expect:</strong>Artworks that not only follow
                  the genre but also bring something new to the table. This
                  could be a unique art style, a fresh take on the genre, or new
                  interpretations of the characters. Judges will look for
                  entries that are daring, experimental, and show a
                  forward-thinking artistic vision. <br />{" "}
                  <strong>Participants Should Aim For:</strong> Think outside
                  the box—whether that means incorporating elements from other
                  art forms or finding new ways to represent Manga/Anime/Cartoon
                  characters. Be bold and unafraid to experiment with your art.
                </li>
                <li>
                  Relevance to the Theme and Cultural Context
                  <br />
                  <strong>Judges Expect:</strong>Art that is not only visually
                  compelling but also culturally relevant to Manga, Anime, and
                  Cartoons. This includes a nod to the history and culture of
                  these art forms, whether it’s a reference to a famous series
                  or a stylistic choice that is characteristic of the medium.{" "}
                  <br /> <strong>Participants Should Aim For:</strong> Make sure
                  your piece feels connected to the Manga/Anime/Cartoon
                  universe. Whether it’s through cultural references, visual
                  style, or familiar character tropes, judges expect the artwork
                  to be grounded in the genre’s traditions.
                </li>
                <li>
                  Overall Impact and Presentation
                  <br />
                  <strong>Judges Expect:</strong>A complete, well-polished piece
                  that works effectively as a whole. The first impression
                  matters: judges want to see a cohesive artwork that can stand
                  on its own, not just as a technical piece but also as an
                  expressive, engaging artwork.
                  <br /> <strong>Participants Should Aim For:</strong> Ensure
                  the final artwork is neat, well-composed, and leaves a strong
                  impression. Take time to refine the final version to make sure
                  all aspects—concept, execution, and presentation—are finished
                  and impactful.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ArtContainer.propTypes = {
  data: PropTypes.object,
};

export default ArtContainer;
