export function decrypt(encryptedText, key) {
  const chunkSize = 4; // Match the length used during encryption
  let decryptedText = "";

  for (let i = 0; i < encryptedText.length; i += chunkSize) {
    let chunk = encryptedText.substring(i, i + chunkSize);
    decryptedText += String.fromCharCode(parseInt(chunk) ^ key);
  }

  return decryptedText;
}
