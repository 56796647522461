import React, { useEffect, useState, useRef } from "react";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useLocation,
} from "react-router-dom";
import Button from "@mui/material/Button";
import { useGetUserContext } from "./LoginActions";
import SubjectCardsForRegistration from "./DashComponents/CardList";
import Logo from "./components/logo/Logo";
import { fakeAuthProvider } from "./LoginActions";
import axios from "axios";
import { envData } from "./envs";
import { calculateSubjectResults } from "./components/AllStudentsTable/examResultCalculator";
import Sidebar from "./Sidebar";
import { dashImages } from "./assets/images";
import $ from "jquery";
import "./style.css";

export default function Dash() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const toggleSidebar = () => () => {
    $(".sidebar, .contents").toggleClass("open");
  };
  const userContext = useGetUserContext();
  const location = useLocation();

  const isMathCogatRoute =
    location.pathname === "/dash/math" ||
    location.pathname === "/dash/cogat" ||
    location.pathname === "/dash/practiceCogat" ||
    location.pathname === "/dash/practiceMath";
  const deselectSidebar = () => (e) => {
    const parentElement = document.querySelector(".menu-items-list");
    const elementsWithClass = parentElement.getElementsByClassName("active");
    for (const element of elementsWithClass) {
      element.classList.remove("active");
    }
  };

  function handleUserSignOut() {
    fakeAuthProvider.signout();
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  }

  const DashBoardImageUrl = () => {
    const userDetailedInfo = fakeAuthProvider.getData();
    return userDetailedInfo.type === "I" ||
      userDetailedInfo.type === "A" ||
      userDetailedInfo.type === "P"
      ? dashImages[1].logo
      : isMathCogatRoute
      ? ""
      : dashImages[0].logo;
  };

  return (
    <div>
      <div className="container-xxl position-relative bg-white d-flex p-0">
        <Sidebar userData={userContext.data} title={title} />
      </div>
      <div
        className="contents"
        style={{
          overflowX: "hidden",
          overflowY: "hidden",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url("${
            location.pathname === "/dash/practiceCogat" ||
            location.pathname === "/dash/practiceMath" ||
            location.pathname === "/dash/draftStudentTable"
              ? ""
              : DashBoardImageUrl()
          }")`,
        }}
      >
        <nav className="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0">
          <a href="/" className="navbar-brand d-flex d-lg-none me-4">
            <div className="col-auto order-0">
              <Logo
                image={`${process.env.PUBLIC_URL}/images/logo/footer-logo.png`}
              />
            </div>
          </a>
          <a
            className="sidebar-toggler flex-shrink-0"
            onClick={toggleSidebar()}
          >
            <i className="fa fa-bars"></i>
          </a>
          <div
            className="navbar-nav align-items-center ms-auto"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            {/* <div style={{ marginRight: "5px" }}>
              <span
                className="d-none d-lg-inline-flex"
                style={{ color: "teal", fontWeight: "bold" }}
              >
                {fakeAuthProvider.getFullname()}
                {userContext.data.username}
              </span>
            </div> */}
            <div>
              <Button
                variant="text"
                onClick={handleUserSignOut}
                className="signOutButton"
              >
                <i
                  className="fas fa-sign-out-alt"
                  style={{ margin: "0 7px" }}
                ></i>
                Sign out
              </Button>
            </div>
          </div>
        </nav>
        <Outlet context={{ setTitle }} />
      </div>
    </div>
  );
}

export function Dash_sub({ fetchFromLocal }) {
  const [open, setOpen] = useState(false);
  const { setTitle } = useOutletContext();

  const [getAddedTEsts, setGet] = useState(false);
  const [studentDataForExamResult, setStudentDataForExamResult] =
    useState(null);
  const [testType, setTestType] = useState("");
  const [alreadyAddedExams, setAlreadyAddedExams] = useState("");
  const [studentData, setStudentData] = useState([]);
  const [examIdForSubmission, setExamIdForSubmission] = useState([]);
  const [availableExams, setAvailableExams] = useState([]);
  const sessionData = sessionStorage.getItem("exams");
  const [addedExams, setAddedExams] = useState(
    sessionData ? JSON.parse(sessionData).length : 0
  );
  const userContext = useGetUserContext();
  const userDetailedInfo = fakeAuthProvider.getData();

  const navigate = useNavigate();
  const handleExamAdd = (examData) => {
    if (sessionStorage.getItem("exams")) {
      const exams = [...JSON.parse(sessionStorage.getItem("exams"))];
      exams.push({ name: examData.examName, id: examData.examId });
      sessionStorage.setItem("exams", JSON.stringify(exams));
      setAddedExams(JSON.parse(sessionStorage.getItem("exams")).length);
      return;
    }
    const exams = [{ name: examData.examName, id: examData.examId }];
    sessionStorage.setItem("exams", JSON.stringify(exams));
    setAddedExams(JSON.parse(sessionStorage.getItem("exams")).length);
  };
  const handleClickOpen = (openOrClose, testType, boolOrExamId) => {
    setOpen(openOrClose);
    setTestType(testType);
    if (boolOrExamId.includes("true")) {
      setGet(boolOrExamId);
    }
    if (typeof boolOrExamId === "string") {
      setExamIdForSubmission(boolOrExamId);
    }
  };
  function intersection(arr1, arr2) {
    const setA = new Set(arr1);
    return arr2.filter((value) => setA.has(value));
  }
  const handlePaymentPage = () => {
    navigate("/dash/payments");
  };

  const getAvailableExams = async () => {
    const token = localStorage.getItem("token");
    try {
      const [request1, request2] = await Promise.all([
        axios.get(`${envData.baseApiUrl}/v1/info/exams`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${envData.baseApiUrl}/v1/students`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      const examNames = request1.data.map((item) => item.examName);
      const addedExams = request2.data.exams.map((item) => item.examName);
      const result = intersection(examNames, addedExams);
      setAvailableExams(request1.data);
      setAlreadyAddedExams(result);
      setStudentData(request2.data.exams);
      setStudentDataForExamResult(request2.data);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    fakeAuthProvider.getData().type !== "A" &&
      fakeAuthProvider.getData().type !== "I" &&
      fakeAuthProvider.getData().type !== "P" &&
      getAvailableExams();
  }, [getAddedTEsts]);

  const results = [];
  if (studentDataForExamResult && studentDataForExamResult.exams.length) {
    for (let i in studentDataForExamResult.exams) {
      results.push(
        calculateSubjectResults(
          studentDataForExamResult.exams,
          studentDataForExamResult.exams[i].examName
        )
      );
    }
  }
  if (results.length) {
    localStorage.setItem("results", JSON.stringify(results));
  }

  return (
    <div>
      {userDetailedInfo.type === "A" ||
      userDetailedInfo.type === "I" ||
      userDetailedInfo.type === "P" ? (
        <h4
          style={{
            marginTop: "10px",
            padding: "10px",
            textAlign: "center",
            color: "#001F3F",
          }}
        >
          Welcome to XCEL competitions Admin portal
        </h4>
      ) : (
        <div>
          <div
            className="row d-flex justify-content-center m-4 "
            style={{
              width: "25%",
              borderRadius: "50%",
            }}
          >
            <div
              style={{
                cursor: "pointer",
                pointerEvents: addedExams ? "" : "none",
              }}
              className="col d-flex justify-content-center"
              onClick={handlePaymentPage}
            >
              <h5
                style={{
                  border: "1px solid black",
                  background: "black",
                  color: "white",
                  borderRadius: "12px",
                  marginTop: "10px",
                  padding: "5px 20px",
                }}
              >
                {" "}
                checkout
              </h5>
              <span
                style={{
                  // border: "2px solid black",
                  borderRadius: "50px",
                  padding: "3px",
                  color: "white",
                  fontSize: "24px",
                  height: "34px",
                  width: "34px",
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {addedExams}
              </span>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!!availableExams.length &&
              availableExams.map((exam, index) => {
                return (
                  <SubjectCardsForRegistration
                    data={exam}
                    handleClickOpen={handleClickOpen}
                    alreadyAddedExams={alreadyAddedExams}
                    key={index}
                    handleExamAdd={handleExamAdd}
                    studentData={studentData}
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}
